////
/// @group _settings/
////

/// Flex сетка
/// @requires {mixin} media
/// @param {Size} $columns [5] - количество колонок
/// @param {Map} $points [()] - карта брейкпоинтов, для которых нужно сделать хелперы
@mixin helpers-cols($columns: 5, $points: ()) {
	// ячейки
	._col {
		&-auto {
			@include flex-cell-width(auto);
			min-width: 1px; // IE11 fix
		}

		// Создаем классы по схеме ._col-3
		@for $i from 1 through $columns {
			$width: percentage(1 / $i);

			&-#{$i} {
				@include flex-cell-width($width);
			}
		}
	}

	// с медиа-запросами по схеме ._lg-col-auto
	@each $point, $size in $points {
		@include media($size) {
			._#{$point}-col-auto {
				@include flex-cell-width(auto);
				min-width: 1px; // IE11 fix
			}
		}
	}

	// Создаем классы по схеме ._lg-col-3
	@for $i from 1 through $columns {
		$width: percentage(1 / $i);
		@each $point, $size in $points {
			@include media($size) {
				._#{$point}-col-#{$i} {
					@include flex-cell-width($width);
				}
			}
		}
	}
}
