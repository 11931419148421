////
/// @group _settings/
////

/// @param {String} $mod [""] - название брейкпоинта с разделителем
/// @access private
@mixin _helpers-float-generate($mod: "") {
	// sass-lint:disable no-important
	._#{$mod}clearfix {
		@include clearfix();
	}

	._#{$mod}clear {
		clear: both !important;
	}

	._#{$mod}ovh {
		overflow: hidden !important;
	}

	._#{$mod}fll {
		float: left !important;
	}

	._#{$mod}flr {
		float: right !important;
	}
	// sass-lint:enable no-important
}

/// Хедперы флоатов
/// @requires {mixin} media
/// @requires {mixin} clearfix
/// @param {Map} $points [()] - карта брейкпоинтов, для которых нужно сделать хелперы
@mixin helpers-float ($points: ()) {
	@include _helpers-float-generate();
	@each $point, $size in $points {
		@include media($size) {
			@include _helpers-float-generate($point + $sep);
		}
	}
}
