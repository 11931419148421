////
/// @group _settings/
////

/// @access private
@mixin _helpers-gutter-sides($name, $prop, $suffix, $value, $mod: "") {
	$start: #{$mod + $name};
	$end: #{$sep + $suffix};

	$sides: (
		t: top,
		r: right,
		b: bottom,
		l: left
	);

	$axis: (
		tb: (top, bottom),
		lr: (left, right),
	);

	// sass-lint:disable no-important
	._#{$start + $end} {
		#{$prop}: $value !important;
	}
	@each $side, $val in $sides {
		._#{$start + $side + $end} {
			#{$prop}#{-#{$val}}: $value !important;
		}
	}
	@each $side, $val in $axis {
		._#{$start + $side + $end} {
			#{$prop}#{-#{nth($val, 1)}}: $value !important;
			#{$prop}#{-#{nth($val, 2)}}: $value !important;
		}
	}
	// sass-lint:enable no-important
}

/// Хедперы отступов
/// @requires {mixin} media
/// @param {Map} $points [()] - карта брейкпоинтов, для которых нужно сделать хелперы
/// @param {Map} $gutters [()] - карта отступов, для которых нужно сделать хелперы
@mixin helpers-gutter ($points: (), $gutters: ()) {
	$props: (
		m: margin,
		p: padding
	);

	@each $name, $prop in $props {
		@include _helpers-gutter-sides($name, $prop, none, 0);
		@each $point, $size in $points {
			@include media($size) {
				@include _helpers-gutter-sides($name, $prop, none, 0, $point + $sep);
			}
		}

		@if ($name == m) {
			@include _helpers-gutter-sides($name, $prop, auto, auto);
			@each $point, $size in $points {
				@include media($size) {
					@include _helpers-gutter-sides($name, $prop, auto, auto, $point + $sep);
				}
			}
		}

		@each $gap, $value in $gutters {
			@include _helpers-gutter-sides($name, $prop, $gap, $value);

			@each $point, $size in $points {
				@include media($size) {
					@include _helpers-gutter-sides($name, $prop, $gap, $value, $point + $sep);
				}
			}
		}
	}
}
