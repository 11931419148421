/* generated */ $argv: (production: false, ftp: false);
////
/// @group style.scss
////

@import "_settings/all";


// загружаются асинхронно
//@import "_blocks-vendors/s2s";
//@import "_blocks-vendors/noty";
//@import "_blocks-vendors/mfp-animate-zoom-in";

// HELPERS
// -------------------------------------------------

@import "_settings/mixins/helpers-cols";
@import "_settings/mixins/helpers-flex";
@import "_settings/mixins/helpers-float";
@import "_settings/mixins/helpers-grid";
@import "_settings/mixins/helpers-gutter";
@import "_settings/mixins/helpers-show-hide";
@import "_settings/mixins/helpers-text";

/// Карта брейкпоинтов для использования в хелперах
/// @type Map
$helpers-breakpoints: (
	// st: map-get($breakpoints, st),
	xs: map-get($breakpoints, xs),
	// psw: map-get($breakpoints, psw),
	sm: map-get($breakpoints, sm),
	ms: map-get($breakpoints, ms),
	// psh: map-get($breakpoints, psh),
	md: map-get($breakpoints, md),
	def: map-get($breakpoints, def),
	lg: map-get($breakpoints, lg),
	// xl: map-get($breakpoints, xl),
	// mg: map-get($breakpoints, mg),
	// dt: map-get($breakpoints, dt),
);

/// Карта отступов для использования в хелперах
/// @type Map
$helpers-gutters: (
	none: map-get($gutters, none),
	sm: map-get($gutters, sm),
	ms: map-get($gutters, ms),
	md: map-get($gutters, md),
	def: map-get($gutters, def),
	lg: map-get($gutters, lg),
	xl: map-get($gutters, xl),
	// mg: map-get($gutters, mg),
	// dt: map-get($gutters, dt),
);


// sass-lint:disable no-css-comments

@include helpers-grid(12, $helpers-breakpoints, $helpers-gutters);
@include helpers-cols(5, $helpers-breakpoints);
@include helpers-flex($helpers-breakpoints);
@include helpers-float(/*$helpers-breakpoints*/);
@include helpers-gutter($helpers-breakpoints, $helpers-gutters);
@include helpers-text(/*$helpers-breakpoints*/);
@include helpers-show-hide($helpers-breakpoints);
// sass-lint:enable no-css-comments
